import { DEFAULT_LANDING_PAGE, LANDING_PAGES } from 'constants/landing-page';
import React from 'react';
import BaseLandingPage from 'widgets/landing-page/BaseLandingPage';
import SeoComponent from 'widgets/seo/SeoComponent';

import './index.css';

function DataDrivenPage() {
  return (
    <BaseLandingPage
      title={LANDING_PAGES.DATA_DRIVEN.title}
      descripntion={LANDING_PAGES.DATA_DRIVEN.description}
      cta={LANDING_PAGES.DATA_DRIVEN.cta}
    />
  );
}

export default DataDrivenPage;

export function Head() {
  return (
    <SeoComponent
      title={LANDING_PAGES.DATA_DRIVEN.seo.title}
      description={LANDING_PAGES.DATA_DRIVEN.seo.description}
      canonicalUrl="labs.goalskeeper.io/data-driven"
    />
  );
}
